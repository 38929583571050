import React from 'react';
import { Result, Button } from 'antd';
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" description="404: Not found" />
    <Result
      status="404"
      title="404"
      subTitle="Questa pagina non essiste."
      extra={<Button type="primary" href="/" title="Home page">Home page</Button>}
    />
  </>
)

export default NotFoundPage
